<template>
  <div class="container pm-0">
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-start flex-wrap">
        <div>
          <InvoiceFilter @filters-changed="setFilters" />
        </div>
        <div>
          <form :action="printInvoicesLink" method="get" target="_blank">
            <button class="btn btn-sm btn-primary rounded-0 ml-2" type="submit">
              <span>طباعة</span>&nbsp;
              <span class="far fa-file-excel"></span>
            </button>
            <input
              type="hidden"
              id="filters"
              name="filters"
              :value="printFilters"
            />
            <input
              type="hidden"
              id="accountant"
              name="accountant"
              :value="isAccountant"
            />
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table table-bordered bg-light">
            <tr class="bg-bb">
              <th>رقم الفاتورة</th>
              <th>تاريخ الفاتورة</th>
              <th>شركة التأمين</th>
              <th>المزاد</th>
              <th>رقم الإدعاء</th>
              <th>قيمة الفاتورة</th>
              <th>تاريخ البيع</th>
              <th>سعر البيع</th>
              <th>التحصيل</th>
              <th>الشطب</th>
              <th>تعديل</th>
            </tr>
            <tr v-if="emptyList">
              <td colspan="12" class="font-weight-bolder">لا يوجد فواتير</td>
            </tr>
            <InvoiceItem
              v-else
              v-for="invoice in list"
              :key="invoice.id"
              :invoice="invoice"
              @canceled="canceled"
            />
          </table>
        </div>
      </div>
    </div>
    <div class="row" v-if="!emptyList">
      <div class="col-sm-12">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ThePaginator from "@/components/UI/ThePaginator";
import InvoiceItem from "@/components/Invoices/InvoiceItem";
import pagination from "@/mixins/pagination";
import InvoiceFilter from "@/components/Invoices/InvoiceFilter";
import arrays from "@/common/arrays";

export default {
  components: { InvoiceFilter, ThePaginator, InvoiceItem },
  mixins: [pagination],
  data() {
    return { formData: {} };
  },
  computed: {
    defaultUrl() {
      return "invoices";
    },
    companyId() {
      return this.$route.params.company;
    },
    isAccountant() {
      return this.$route.name === "accountant" ? 1 : 0;
    },
    printInvoicesLink() {
      return `${this.$store.getters.publicPath}/api/invoices/export`;
    },
    printFilters() {
      try {
        return JSON.stringify(this.filters);
      } catch {
        return this.filters;
      }
    },
  },
  methods: {
    canceled(id) {
      this.list = arrays.removeById(this.list, id);
    },
  },
  async created() {
    this.setFilters({
      company_id: this.companyId,
    });
  },
};
</script>
