<template>
  <tr v-if="!!invoiceData">
    <td>{{ invoiceData.number }}</td>
    <td>{{ formattedDate }}</td>
    <td>
      <router-link class="link" :to="{name: 'invoices.list', params: {company: invoiceData.company_id}}">
        {{ invoiceData.company_name }}
      </router-link>
    </td>
    <td>
      <router-link class="link" v-if="hasAuction && auctionName" :to="{name: 'auctions.status', params: {auction: auctionId}}">
        {{ auctionName }}
      </router-link>
      <p class="pm-0 font-weight-bolder" v-else-if="!!auctionName">
        <span class="d-block pm-0">{{ auctionName }}</span>
        <small class="font-weight-bold text-danger">(محذوف)</small>
      </p>
      <p class="pm-0 font-weight-bolder text-danger" v-else>المزاد غير محدد</p>
    </td>
    <td>{{ invoiceData.claim_no }}</td>
    <td>{{ invoiceData.value }}</td>
    <td>{{ formattedFinishDate }}</td>
    <td>{{ invoiceData.current_price }}</td>
    <td><span class="badge" :class="paid ? 'badge-success' : 'badge-danger'">{{ paid ? 'مدفوع' : 'غير مدفوع' }}</span></td>
    <td>
      <small v-if="!!invoiceStatus" class="badge" :class="invoiceStatus.class">{{ invoiceStatus.text }}</small>
    </td>
    <td>
      <download-invoice :invoice-id="invoiceData.id" trigger-class="btn btn-sm btn-info nr-block mx-0 mb-1">
        طباعة
      </download-invoice>
      <modal-btn v-if="!isAccountant" :target="`#${cancelId}`" btn-class="btn btn-sm btn-danger nr-block mx-0 mb-1" :disabled="cannotCancel">
        الشطب
      </modal-btn>
      <modal-btn v-if="!isAccountant" :target="`#${paymentId}`" btn-class="btn btn-sm btn-success nr-block mx-0 mb-1">
        الدفع
      </modal-btn>
      <teleport to="#app" v-if="!isAccountant">
        <confirm-model
          v-if="!cannotCancel"
          :target-id="cancelId"
          confirm-text="شطب"
          title="شطب فاتورة"
          @confirmed="cancelInvoice">
          <p class="pm-0 text-left">هل أنت متأكد من شطب الفاتورة؟</p>
          <p class="link danger text-left">يرجى العلم أن هذه العملية لايمكن الرجوع عنها</p>
        </confirm-model>
        <confirm-model
          :target-id="paymentId"
          title="دفع الفاتورة"
          @confirmed="payInvoice">
          <p class="text-left">يرجى تحديد حالة الدفع</p>
          <BootstrapSelect title="دفع الفاتورة" id="payment" :options="paymentOptions" :empty-option="false" />
        </confirm-model>
      </teleport>
    </td>
  </tr>
</template>

<script>
import DownloadInvoice from "@/components/Invoices/DownloadInvoice";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import types from "@/common/types";
import date from "@/common/dateMethods";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";

export default {
  components: {BootstrapSelect, ConfirmModel, ModalBtn, DownloadInvoice},
  props: {invoice: {required: true}},
  data() {
    return {
      invoiceData: {}
    };
  },
  computed: {
    auctionId() {
      return !this.invoiceData ? '' : this.invoiceData.auction_id;
    },
    cancelId() {
      return !this.invoiceData ? '' : !!this.invoiceData.id;
    },
    paymentId() {
      return !this.invoiceData ? '' : ('payment' + !!this.invoiceData.id);
    },
    formattedDate() {
      return !!this.invoiceData && !!this.invoiceData.created_at ? date.withoutTime(this.invoiceData.created_at) : ''
    },
    formattedFinishDate() {
      return !!this.invoiceData && !!this.invoiceData.finish_date ? date.withoutTime(this.invoiceData.finish_date) : ''
    },
    cannotCancel() {
      return !this.invoiceData || types.checkY(this.invoiceData.canceled);
    },
    hasAuction() {
      return !this.invoiceData ? '' : !!this.invoiceData.auction_count > 0;
    },
    invoiceStatus() {
      if (!this.invoiceData)
        return '';
      const check = types.checkY(this.invoiceData.canceled);
      return {
        text: check ? 'مشطوبة' : 'غير مشطوبة',
        class: check ? 'badge-danger' : 'badge-success'
      }
    },
    auctionName() {
      return !!this.invoiceData ? this.invoiceData.name : ''
    },
    paid() {
      return !!this.invoiceData && types.checkY(this.invoiceData.paid)
    },
    paymentOptions() {
      return [
        {id: 'y', content: 'دفع'},
        {id: 'n', content: 'إلغاء الدفع'},
      ]
    },
    isAccountant() {
      return this.$route.name === 'accountant'
    }
  },
  methods: {
    setInvoice(invoice) {
      if (!!invoice)
        this.invoiceData = invoice;
    },
    async cancelInvoice() {
      if (!this.invoiceData) {
        return console.error('invoice is not detected');
      }

      const response = await http.send('invoices/cancel', {invoice: this.invoiceData.id});
      if (http.responseAccepted(response)) {
        await this.hideModal(`#${this.cancelId}`);
        this.invoiceData.canceled = response.data.canceled;
        this.$emit('canceled', this.invoiceData.id)
        http.successMessage('تم الشطب بنجاح')
      }
    },
    async payInvoice() {
      const payment = $_('#payment').val()
      if (!payment)
        return http.popupMessage('error', 'يرجى تحديد حالة الدفع')
      if (!!this.invoiceData) {
        const response = await http.send('invoices/pay', {invoice: this.invoiceData.id, payment})
        if (http.responseAccepted(response))
          this.invoiceData.paid = response.data.paid
        this.hideModal(`#${this.paymentId}`);
      }
    }
  },
  created() {
    this.setInvoice(this.invoice);
  }
}
</script>